import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Tag,
    TagLabel,
    Text,
    useBreakpointValue,
    WrapItem,
    useColorModeValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import SupComponent from '../common/SupComponent';
import axios from 'axios';
import { getAuthorizedHeader } from '../../common/auth';
import ModifComponent, { IModifyFormData } from './ModifComponent';
import ButtonComponent from "../common/ButtonComponent";
import { useTranslation } from "react-i18next";
import ModifyPasswordComponent from '../settings/ModifyPasswordComponent';

interface EmployeeDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    employee: {
        _id: string;
        firstname: string;
        lastname: string;
        profilePicture: string;
        phone: string;
        email: string;
        job: string;
    };
}


async function DeleteEmployee(userId: string) {
    try {
        await axios.delete('/api/user/' + userId, {
            headers: getAuthorizedHeader(),
        });
        alert("Employee deleted successfully!");
        window.location.reload();
    } catch (error) {
        console.error("Error deleting user:", error);
    }
}

function EmployeeDetailsModal(props: EmployeeDetailsModalProps) {
    const { t } = useTranslation();
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const isOpen = props.isOpen;
    const onClose = props.onClose;
    const employee = props.employee;
    const profilePictureUrl = process.env.REACT_APP_API_URL as string;
    const [isModifModalOpen, setIsModifModalOpen] = useState(false);
    const [modifFormData, setModifFormData] = useState({
        email: employee.email,
        job: employee.job,
        phone: employee.phone,
        firstname: employee.firstname,
        lastname: employee.lastname,
    } as IModifyFormData);
    const [modifyPassword, setModifyPassword] = useState(false);
    const openModifModal = () => {
        setIsModifModalOpen(true);
    };

    const closeModifModal = () => {
        setIsModifModalOpen(false);
    };

    const handleModifFormSubmit = (data: IModifyFormData) => {
        setModifFormData(data);
        const { email, firstname, lastname, phone, job } = data;

        axios.put('/api/user/' + employee._id, {
            firstname,
            lastname,
            phone,
            job,
            email
        }, { headers: getAuthorizedHeader() })
            .then(() => Promise<void>)
            .catch(reason => Promise.reject(new Error('Unable to update profile: ' + reason)));
        closeModifModal();
    };

    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='2px' />
                <ModalContent>
                    <ModalHeader>{modifFormData.firstname} {modifFormData.lastname}</ModalHeader>
                    <ModalCloseButton
                        position="absolute"
                        top="1rem"
                        right="1rem"
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                        _active={{ bg: 'transparent' }}
                        sx={{
                            '& svg': {
                                transition: 'all 0.2s ease-in-out',
                            },
                            '& svg path': {
                                stroke: 'white',
                                transition: 'stroke 0.3s ease-in-out',
                            },
                            '&:hover svg path': {
                                stroke: 'red',
                                transition: 'stroke 0.5s ease-in-out 0s',
                            },
                        }}
                    />
                    <ModalBody>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} alignItems="center">
                            <WrapItem>
                                <Box
                                    h="100px"
                                    w="100px"
                                    rounded="full"
                                    overflow="hidden"
                                    borderWidth="1px"
                                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                                >
                                    {employee.profilePicture ? (
                                        <Image
                                            src={profilePictureUrl + '/api/user/picture/' + employee.profilePicture}
                                            alt={`Profile of ${modifFormData.firstname} ${modifFormData.lastname}`}
                                            objectFit="cover"
                                            w="100%"
                                            h="100%"
                                        />
                                    ) : (
                                        <Avatar
                                            size="xl"
                                            name={`${modifFormData.firstname} ${modifFormData.lastname}`}
                                            color="white"
                                            bg="gray.500"
                                        />
                                    )}
                                </Box>
                            </WrapItem>
                            <Box>
                                <Text fontSize="lg" fontWeight="bold">
                                    {t('telephone')}: <span style={{ fontWeight: 'normal' }}>{modifFormData.phone}</span>
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    {t('email')}: <span style={{ fontWeight: 'normal' }}>{modifFormData.email}</span>
                                </Text>
                                <Tag
                                    size="lg"
                                    colorScheme="red"
                                    borderRadius="full"
                                    mt={isSmallScreen ? '2' : '0'}
                                >
                                    <TagLabel>{modifFormData.job}</TagLabel>
                                </Tag>
                            </Box>
                        </SimpleGrid>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="purple" mr={3} onClick={() => setModifyPassword(true)}>
                            {t("modifyPassword")}
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={openModifModal}>
                            {t("modify")}
                        </Button>
                        <Button colorScheme="red" onClick={() => DeleteEmployee(employee._id)}>
                            {t("delete")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
                <Modal isOpen={isModifModalOpen} onClose={closeModifModal}>
                    <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='2px' />
                    <ModalContent>
                        <ModalHeader>{t('modifyEmployee')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <ModifComponent callback={handleModifFormSubmit} formData={modifFormData} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Modal>
            <ModifyPasswordComponent
                isOpen={modifyPassword}
                handleCloseModal={() => setModifyPassword(false)}
                userId={props.employee._id}
            />
        </Box>
    );
}

export default EmployeeDetailsModal;
