import React from 'react';
import { Avatar, Flex, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export interface Employee {
    _id: string;
    firstname: string;
    lastname: string;
    profilePicture: string;
    phone: string;
    email: string;
    job: string;
}

interface EmployeeCarouselProps {
    employees: Employee[];
    currentIndex: number;
    prevEmployee: () => void;
    nextEmployee: () => void;
    handleEmployeeSelect: (employeeIndex: number) => void;
    selectedEmployeeName: string;
}

const EmployeeCarousel: React.FC<EmployeeCarouselProps> = ({
                                                               employees,
                                                               currentIndex,
                                                               prevEmployee,
                                                               nextEmployee,
                                                               handleEmployeeSelect,
                                                               selectedEmployeeName,
                                                           }) => {
    const currentEmployee = employees[currentIndex];

    return (
        <Flex justifyContent="center" alignItems="center" mx="auto" my={4}>
            <IconButton
                icon={<ChevronLeftIcon />}
                onClick={prevEmployee}
                aria-label="Previous Employee"
            />

            <Flex direction="column" align="center">
                <Avatar
                    name={`${currentEmployee.firstname} ${currentEmployee.lastname}`}
                    size="2xl"
                    mx={4}
                    onClick={() => handleEmployeeSelect(currentIndex)}
                    border={
                        selectedEmployeeName ===
                        `${currentEmployee.firstname} ${currentEmployee.lastname}`
                            ? '3px solid black'
                            : 'none'
                    }
                />
                <Text mt={2}>
                    {currentEmployee.firstname} {currentEmployee.lastname}
                </Text>
            </Flex>

            <IconButton
                icon={<ChevronRightIcon />}
                onClick={nextEmployee}
                aria-label="Next Employee"
            />
        </Flex>
    );
};

export default EmployeeCarousel;
