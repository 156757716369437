import React, {useEffect, useState} from 'react';
import {
    Box,
    Text,
    useColorModeValue,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Flex,
    Avatar,
    AvatarGroup,
    Button, useToast,
} from '@chakra-ui/react';
import {differenceInDays, addDays, format, isWithinInterval} from 'date-fns';
import {useTranslation} from 'react-i18next';
import CreateTaskModal from './CreateTaskModal';
import ButtonComponent from '../../../common/ButtonComponent';
import {getStatusColor} from "../../../../common/status";
import axios from "axios";
import {getAuthorizedHeader} from "../../../../common/auth";
import TaskDetail from "./TaskDetail";

export interface Construction {
    name: string;
    startDate: string;
    endDate: string;
    assignedTo: {
        type: 'person' | 'team';
        name: string;
        members?: string[];
    };
}

export interface TaskConstruction {
    _id?: string;
    name: string;
    startDate: string;
    endDate: string;
    type: 'person' | 'team';
    assignedId: string;
    assignedName: string;
    status: boolean;
}

export interface ConstructionTasksProps {
    projectReference: string;
    projectStartDate: string;
    projectEndDate: string;
    projectId: string;
    constructionTasks: string[];
    fetchProject: () => void;
}

function GanttTasks(props: ConstructionTasksProps) {
    const {t} = useTranslation();
    const [validatedTasks, setValidatedTasks] = useState<{ [key: string]: boolean }>({});
    const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState(false);
    const toast = useToast();
    const [tasks, setTasks] = useState<TaskConstruction[]>([]);

    const generateDays = (startDate: string, endDate: string, fuck: boolean) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const numberOfDays = fuck ? differenceInDays(end, start): differenceInDays(end, start) + 2;
        const days = [];
        for (let i = 1; i < numberOfDays; i++) {
            const currentDate = addDays(start, i);
            days.push({
                date: format(currentDate, 'dd/MM/yyyy'),
                dayStart: currentDate,
            });
        }
        return days;
    };

    const handleCreateTask = async (newTask: TaskConstruction) => {
        try {
            const data = {...newTask, projectId: props.projectId};
            await axios.post('/api/constructionTask', data, {
                headers: getAuthorizedHeader(),
            });
            toast({
                title: 'Task created',
                description: 'The new task was successfully created.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            props.fetchProject();
        } catch (error) {
            console.error('Error creating task:', error);

            toast({
                title: 'Error',
                description: 'There was a problem creating the task.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setCreateTaskModalOpen(false);
    };

    const fetchAllTasks = async () => {
        try {
            const taskPromises = props.constructionTasks.map((taskId) =>
                axios.get(`/api/constructionTask/${taskId}`, {
                    headers: getAuthorizedHeader(),
                })
            );
            const responses = await Promise.all(taskPromises);
            const fetchedTasks = responses.map((r) => r.data);

            setTasks(fetchedTasks);
        } catch (error) {
            console.error('Error fetching tasks by IDs:', error);
            toast({
                title: 'Error',
                description: 'Unable to load tasks.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (!props.constructionTasks || props.constructionTasks.length === 0) {
            return;
        }
        fetchAllTasks();
    }, [props.constructionTasks]);

    return (
        <Box width="100%" marginTop={2} bg={"none"}>
            <Box bg={useColorModeValue('white', 'gray.600')} borderRadius={4} rounded="md" boxShadow="md" p={4}>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading fontSize='2xl' color="gray.300">{t('PlaningChantier')}</Heading>
                    <ButtonComponent
                        text={t('Nt')}
                        callback={() => setCreateTaskModalOpen(true)}
                    />
                </Flex>
                <CreateTaskModal
                    isOpen={isCreateTaskModalOpen}
                    onClose={() => setCreateTaskModalOpen(false)}
                    handleCreateTask={handleCreateTask}
                    projectId={props.projectId}
                />
                <Box width="100%" overflowX="auto">
                    <TableContainer bg={useColorModeValue('gray.100', 'gray.400')}>
                        <Table variant="simple" bg={useColorModeValue('gray.100', 'gray.500')}>
                            <Thead>
                                <Tr>
                                    <Th>{t('project')}</Th>
                                    <Th>{t('Begin')}</Th>
                                    {generateDays(props.projectStartDate, props.projectEndDate, true).map((day, index) => (
                                        <Th key={index} textAlign="center">
                                        </Th>
                                    ))}
                                    <Th>{t('End')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <React.Fragment>
                                    <Tr bg={useColorModeValue('gray.100', 'gray.500')}>
                                        <Td fontWeight="bold" paddingY="1rem">{props.projectReference}</Td>
                                        {generateDays(props.projectStartDate, props.projectEndDate, false).map((day, index) => (
                                            <Td key={index} textAlign="center">
                                                <Text fontSize="sm" color="gray.500">{day.date}</Text>
                                            </Td>
                                        ))}
                                    </Tr>
                                    {tasks.map((task, taskIndex) => {
                                        const days = generateDays(props.projectStartDate, props.projectEndDate, false);
                                        const taskStart = new Date(task.startDate);
                                        const taskEnd = new Date(task.endDate);
                                        const startDayIndex = days.findIndex(day => isWithinInterval(taskStart, {
                                            start: day.dayStart,
                                            end: day.dayStart
                                        }));
                                        const endDayIndex = days.findIndex(day => isWithinInterval(taskEnd, {
                                            start: day.dayStart,
                                            end: day.dayStart
                                        }));
                                        const colSpan = endDayIndex - startDayIndex + 1;
                                        const isTaskValidated = validatedTasks[task.name];

                                        return (
                                            <Tr key={taskIndex} bg={taskIndex % 2 === 0 ? "white" : "gray.50"}>
                                                <Td paddingY="0.75rem" colSpan={2}>
                                                    <Text fontWeight="medium">{task.name}</Text>
                                                    <Text fontSize="sm"
                                                          color="gray.500">{task.startDate}</Text>
                                                    <Text fontSize="sm"
                                                          color="gray.500">{task.endDate}</Text>
                                                </Td>
                                                {days.map((day, index) => (
                                                    index === startDayIndex ? (
                                                        <TaskDetail index={index} colSpan={colSpan} task={task} fetchTask={fetchAllTasks}/>
                                                    ) : index > startDayIndex && index <= endDayIndex ? null : (
                                                        index === startDayIndex + 3 ? (
                                                            <Td key={index} textAlign="center" colSpan={colSpan}>
                                                                <Text fontSize="sm" color="gray.400">...</Text>
                                                            </Td>
                                                        ) : (
                                                            <Td key={index}></Td>
                                                        )
                                                    )
                                                ))}
                                            </Tr>
                                        );
                                    })}
                                </React.Fragment>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
};

export default GanttTasks;
