import React from 'react';
import {
    Avatar,
    AvatarGroup,
    Box,
    Flex,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Team {
    _id?: string;
    name: string;
    members: string[];
}

interface TeamCarouselProps {
    teams: Team[];
    currentTeamIndex: number;
    prevTeam: () => void;
    nextTeam: () => void;
    handleTeamSelect: (teamIndex: number) => void;
    selectedTeamName: string;
}

const TeamCarousel: React.FC<TeamCarouselProps> = ({
                                                       teams,
                                                       currentTeamIndex,
                                                       prevTeam,
                                                       nextTeam,
                                                       handleTeamSelect,
                                                       selectedTeamName
                                                   }) => {
    if (!teams || teams.length === 0) {
        return <Text textAlign="center" mt={4}>No teams available</Text>;
    }

    const safeIndex = Math.min(Math.max(currentTeamIndex, 0), teams.length - 1);
    const currentTeam = teams[safeIndex];

    return (
        <Flex justifyContent="center" alignItems="center" mx="auto" my={4}>
            <IconButton
                icon={<ChevronLeftIcon />}
                onClick={prevTeam}
                aria-label="Previous Team"
                mr={3}
            />

            <Popover trigger="hover" placement="top">
                <PopoverTrigger>
                    <Box textAlign="center" cursor="pointer">
                        <Avatar
                            name={currentTeam.name}
                            size="2xl"
                            mx="auto"
                            onClick={() => handleTeamSelect(safeIndex)}
                            border={
                                selectedTeamName === currentTeam.name
                                    ? '3px solid black'
                                    : 'none'
                            }
                        />
                        <Text mt={2} fontWeight="semibold">
                            {currentTeam.name}
                        </Text>
                    </Box>
                </PopoverTrigger>

                <PopoverContent>
                    <PopoverBody>
                        <Text fontWeight="bold" mb={1}>
                            Membres de l'équipe:
                        </Text>
                        <AvatarGroup size="sm" max={4}>
                            {currentTeam.members?.map((member, index) => (
                                <Avatar key={index} name={member} />
                            ))}
                        </AvatarGroup>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

            <IconButton
                icon={<ChevronRightIcon />}
                onClick={nextTeam}
                aria-label="Next Team"
                ml={3}
            />
        </Flex>
    );
};

export default TeamCarousel;
