import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text,
    ModalCloseButton,
    Stack,
    Flex,
    IconButton,
    Box,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    useToast,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import SearchComponent from "../common/SearchComponent";
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";

interface TeamDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    fetchTeams: () => void;
    team: {
        _id: string;
        name: string;
        employees: string[];
    };
}

interface IUser {
    _id: string;
    lastname: string;
    firstname: string;
    job: string;
}

function TeamDetailsModal({ isOpen, onClose, team, fetchTeams }: TeamDetailsModalProps) {
    if (!team) return null;

    const [isEditing, setIsEditing] = useState(false);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [teamMembers, setTeamMembers] = useState<IUser[]>([]);
    const [searchResults, setSearchResults] = useState<IUser[]>([]);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const toast = useToast();

    useEffect(() => {
        setIsEditing(false);
        setTeamMembers([]);
        if (team.employees.length > 0) {
            fetchAllTeamMembers();
        }
    }, [team.employees]);

    const updateTeamMembers = async () => {
        try {
            await axios.put(
                `/api/teams/${team._id}/members`,
                { members: teamMembers.map(member => member._id) },
                { headers: getAuthorizedHeader() }
            );
            toast({ title: "Team updated successfully!", status: "success", duration: 3000, isClosable: true });
            fetchTeams();
            onClose();
        } catch (error) {
            toast({ title: "Failed to update team members", status: "error", duration: 3000, isClosable: true });
            console.error('Failed to update team members:', error);
        }
    };

    const handleSearch = async (searchQuery: string) => {
        setSearchEmployee(searchQuery);
        try {
            const response = await axios.get(`/api/employees/search?query=${searchQuery}`, {
                headers: getAuthorizedHeader(),
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error searching employees:', error);
        }
    };

    const handleAddMember = (member: IUser) => {
        if (teamMembers.find(m => m._id === member._id)) {
            toast({ title: "Member already in team", status: "info", duration: 2000, isClosable: true });
            return;
        }
        setTeamMembers([...teamMembers, member]);
    };

    const handleRemoveMember = (memberId: string) => {
        setTeamMembers(teamMembers.filter(member => member._id !== memberId));
    };

    const fetchAllTeamMembers = async () => {
        const membersDetails = await Promise.all(
            team.employees.map(async (userId) => {
                try {
                    const response = await axios.get(`/api/user/${userId}`, {
                        headers: getAuthorizedHeader(),
                    });
                    return response.data;
                } catch (error) {
                    console.error('Failed to fetch user details:', error);
                    return null;
                }
            })
        );
        setTeamMembers(membersDetails.filter(member => member !== null));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW={isSmallScreen ? "90%" : "50%"} borderRadius="md" boxShadow="lg">
                <ModalHeader>{team.name} - {isEditing ? "Edit Team" : "Team Details"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!isEditing ? (
                        <Stack spacing={3}>
                            {teamMembers.map(member => (
                                <Flex
                                    key={member._id}
                                    justify="space-between"
                                    align="center"
                                    p={3}
                                    rounded="md"
                                    boxShadow="sm"
                                    border="2px solid"
                                    borderColor={isEditing ? "green.400" : "transparent"}
                                    bg={useColorModeValue("white", "gray.800")}
                                >
                                    <Text>{member.firstname} {member.lastname} - {member.job}</Text>
                                </Flex>
                            ))}
                        </Stack>

                    ) : (
                        <Box
                            rounded="md"
                            boxShadow="md"
                            bg={useColorModeValue("gray.100", "gray.700")}
                            p={4}
                            mb={4}
                        >
                            <SearchComponent value={searchEmployee} onChange={handleSearch} />
                            <Stack spacing={3} mt={4}>
                                {searchResults.map((employee: IUser) => {
                                    const isMember = teamMembers.some(member => member._id === employee._id);
                                    return (
                                        <Flex
                                            key={employee._id}
                                            justify="space-between"
                                            align="center"
                                            p={3}
                                            rounded="md"
                                            boxShadow="sm"
                                            border="2px solid"
                                            borderColor={isMember ? "green.400" : "red.400"}
                                            bg={useColorModeValue("white", "gray.800")}
                                        >
                                            <Text>
                                                {employee.firstname} {employee.lastname} - {employee.job}
                                            </Text>
                                            {isMember ? (
                                                <IconButton
                                                    aria-label="Remove member"
                                                    icon={<CloseIcon />}
                                                    size="sm"
                                                    colorScheme="red"
                                                    onClick={() => handleRemoveMember(employee._id)}
                                                />
                                            ) : (
                                                <IconButton
                                                    aria-label="Add employee"
                                                    icon={<AddIcon />}
                                                    size="sm"
                                                    colorScheme="green"
                                                    onClick={() => handleAddMember(employee)}
                                                />
                                            )}
                                        </Flex>
                                    );
                                })}
                            </Stack>
                        </Box>
                    )}
                </ModalBody>
                <Divider />
                <ModalFooter>
                    {isEditing && (
                        <Button colorScheme="blue" onClick={updateTeamMembers}>
                            Save Changes
                        </Button>
                    )}
                    <Button ml={3} onClick={() => setIsEditing(!isEditing)}>
                        {isEditing ? "Finish Editing" : "Edit"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default TeamDetailsModal;
