import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Input,
    Box,
    Select,
    IconButton,
    useColorModeValue
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import ButtonComponent from '../../../common/ButtonComponent';
import TeamCarousel from './TeamCarousel';
import EmployeeCarousel from './EmployeeCarousel';
import ManageTeamsAccordion from './ManageTeamsAccordion';
import {SettingsIcon} from '@chakra-ui/icons';
import axios from 'axios';
import {getAuthorizedHeader} from '../../../../common/auth';
import PageLoadingSpinner from '../../../common/PageLoadingSpinner';
import {TaskConstruction} from './GanttTasks';

interface CreateTaskModalProps {
    isOpen: boolean;
    onClose: () => void;
    projectId: string;
    handleCreateTask: (newTask: TaskConstruction) => void;
}

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
                                                             isOpen,
                                                             onClose,
                                                             handleCreateTask,
                                                             projectId,
                                                         }) => {
    const {t} = useTranslation();

    const [employees, setEmployees] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);

    const [newTask, setNewTask] = useState<TaskConstruction>({
        _id: '',
        name: '',
        startDate: '',
        endDate: '',
        type: 'person',
        assignedId: '',
        assignedName: '',
        status: false,
    });

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
    const [manageMode, setManageMode] = useState(false);

    const nextEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % employees.length);
    };
    const prevEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + employees.length) % employees.length);
    };

    const nextTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex + 1) % teams.length);
    };
    const prevTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex - 1 + teams.length) % teams.length);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setNewTask((prev) => ({...prev, [name]: value}));
    };

    const handleAssignedToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewTask((prev) => ({...prev, type: e.target.value as 'person' | 'team'}));
    };

    const handleTeamSelect = (teamIndex: number) => {
        const team = teams[teamIndex];
        if (!team) return;
        setNewTask((prev) => ({
            ...prev,
            type: 'team',
            assignedId: team._id,
            assignedName: team.name,
        }));
    };

    const handleEmployeeSelect = (employeeIndex: number) => {
        const employee = employees[employeeIndex];
        if (!employee) return;
        setNewTask((prev) => ({
            ...prev,
            type: 'person',
            assignedId: employee._id,
            assignedName: `${employee.firstname} ${employee.lastname}`,
        }));
    };


    const handleCreate = () => {
        handleCreateTask(newTask);
        onClose();
    };

    const inputBorderColor = useColorModeValue('gray.300', 'gray.600');
    const buttonHoverColor = useColorModeValue('gray.200', 'gray.700');
    const modalBg = useColorModeValue('white', 'gray.800');

    const fetchEmployees = async () => {
        try {
            const response = await axios.get('/api/users', {
                headers: getAuthorizedHeader(),
            });
            setEmployees(response.data);
            if (employees.length !== 0)
                handleEmployeeSelect(0);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get('/api/teams', {
                headers: getAuthorizedHeader(),
            });
            setTeams(response.data);
            if (teams.length !== 0)
                handleTeamSelect(0);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    // When component mounts, fetch employees & teams
    useEffect(() => {
        fetchEmployees();
        fetchTeams();
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                bg={modalBg}
                borderRadius="md"
                boxShadow="xl"
                transition="all 0.3s ease-in-out"
            >
                <ModalHeader fontWeight="bold" fontSize="xl">
                    {t('Nt')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box width="100%">
                        <SimpleGrid columns={2} spacing={9} mb={6}>
                            {/* TASK NAME */}
                            <Input
                                placeholder={t('Nom')}
                                name="name"
                                value={newTask.name}
                                onChange={handleInputChange}
                                variant="flushed"
                                borderBottomColor={inputBorderColor}
                                _hover={{ borderBottomColor: buttonHoverColor }}
                            />
                            {/* START DATE */}
                            <Box>
                                <Input
                                    placeholder={t('Begin')}
                                    name="startDate"
                                    type="date"
                                    value={newTask.startDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                    borderBottomColor={inputBorderColor}
                                    _hover={{ borderBottomColor: buttonHoverColor }}
                                />
                            </Box>
                            {/* PERSON vs TEAM */}
                            <Select
                                variant="flushed"
                                name="type"
                                onChange={handleAssignedToChange}
                                value={newTask.type}
                                borderBottomColor={inputBorderColor}
                                _hover={{ borderBottomColor: buttonHoverColor }}
                            >
                                <option value="person">{t('Person')}</option>
                                <option value="team">{t('Team')}</option>
                            </Select>
                            {/* END DATE */}
                            <Box>
                                <Input
                                    placeholder={t('End')}
                                    name="endDate"
                                    type="date"
                                    value={newTask.endDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                    borderBottomColor={inputBorderColor}
                                    _hover={{ borderBottomColor: buttonHoverColor }}
                                />
                            </Box>
                        </SimpleGrid>

                        {newTask.type === 'team' ? (
                            teams.length === 0 ? (
                                <Box>{t('NoTeamsAvailable')}</Box>
                            ) : (
                                <TeamCarousel
                                    teams={teams}
                                    currentTeamIndex={currentTeamIndex}
                                    prevTeam={prevTeam}
                                    nextTeam={nextTeam}
                                    handleTeamSelect={handleTeamSelect}
                                    selectedTeamName={newTask.assignedName}
                                />
                            )
                        ) :
                            employees.length !== 0 ? (
                                <EmployeeCarousel
                                    employees={employees}
                                    currentIndex={currentIndex}
                                    prevEmployee={prevEmployee}
                                    nextEmployee={nextEmployee}
                                    handleEmployeeSelect={handleEmployeeSelect}
                                    selectedEmployeeName={newTask.assignedName}
                                />
                            ) : (
                                <PageLoadingSpinner />
                            )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <ButtonComponent
                        text={t('CreateTask')}
                        callback={handleCreate}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateTaskModal;
