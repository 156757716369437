import React, { useState } from 'react';
import {
    Box,
    Heading,
    Text,
    Stack,
    useColorModeValue,
    Avatar,
    Icon,
    Flex,
    IconButton,
    Tooltip,
    useToast
} from '@chakra-ui/react';
import { FaUsers, FaTrash } from 'react-icons/fa';
import TeamDetailsModal from "./TeamDetailsModal";
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";

interface TeamListProps {
    teams: any;
    fetchTeams: () => void;
}

function TeamList(props: TeamListProps) {
    const bgColor = useColorModeValue('white', 'gray.800');
    const hoverColor = useColorModeValue('gray.100', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const toast = useToast();

    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleBoxClick = (team: any) => {
        setSelectedTeam(team);
        setIsModalOpen(true);
    };

    async function deleteTeam(teamId: string) {
        try {
            await axios.delete(`/api/team/${teamId}`, {
                headers: getAuthorizedHeader(),
            });
            toast({
                title: "Team deleted successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            props.fetchTeams();
        } catch (error) {
            toast({
                title: "Error deleting team.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error("Error deleting team:", error);
        }
    }

    return (
        <Box px={4} py={6} maxHeight="calc(100% - 70px)" overflowY="auto" className="custom-scrollbar">
            <Stack spacing={5}>
                {props.teams.map((team: any) => (
                    <Flex
                        key={team._id}
                        shadow="md"
                        borderWidth="1px"
                        borderColor={borderColor}
                        borderRadius="lg"
                        bg={bgColor}
                        alignItems="center"
                        p={4}
                        cursor="pointer"
                        _hover={{ bg: hoverColor, transform: 'scale(1.02)' }}
                        transition="all 0.2s ease"
                        onClick={() => handleBoxClick(team)}
                        position="relative"
                    >
                        <Avatar
                            name={team.name}
                            src={team.imageUrl}
                            size="lg"
                            bg="blue.500"
                            color="white"
                            mr={4}
                        />

                        <Flex flexDirection="column" flexGrow={1}>
                            <Heading fontSize="lg" fontWeight="semibold" color={useColorModeValue('gray.800', 'gray.200')}>
                                {team.name}
                            </Heading>
                            <Flex alignItems="center" mt={1}>
                                <Icon as={FaUsers} mr={2} color="blue.400" />
                                <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')}>
                                    {team.employees.length} members
                                </Text>
                            </Flex>
                        </Flex>

                        <Box onClick={(e) => e.stopPropagation()} position="absolute" top={2} right={2}>
                            <Tooltip label="Delete Team" fontSize="sm">
                                <IconButton
                                    aria-label="Delete team"
                                    icon={<FaTrash />}
                                    size="sm"
                                    colorScheme="red"
                                    onClick={() => deleteTeam(team._id)}
                                    opacity={0.6}
                                    _hover={{ opacity: 1 }}
                                    transition="opacity 0.2s ease"
                                />
                            </Tooltip>
                        </Box>
                    </Flex>
                ))}
            </Stack>

            {selectedTeam && (
                <TeamDetailsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    team={selectedTeam}
                    fetchTeams={props.fetchTeams}
                />
            )}
        </Box>
    );
}

export default TeamList;
