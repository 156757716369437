import React, { useEffect, useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Td,
    Text,
    useDisclosure,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { TaskConstruction } from "./GanttTasks";
import { getAuthorizedHeader } from "../../../../common/auth";
import { getStatusColor } from "../../../../common/status";

interface UserInfo {
    _id: string;
    firstname: string;
    lastname: string;
    profilePicture?: string;
}

interface TeamInfo {
    _id: string;
    name: string;
    employees: string[];
}

interface TaskDetailProps {
    index: number;
    colSpan: number;
    task: TaskConstruction;
    fetchTask: () => void;
}

function TaskDetail(props: TaskDetailProps) {
    const { index, colSpan, task, fetchTask } = props;
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const isTaskValidated = task.status;
    const taskColor = isTaskValidated ? "green.300" : "blue.300";

    const [user, setUser] = useState<UserInfo | null>(null);
    const [team, setTeam] = useState<TeamInfo | null>(null);
    const [teamMembers, setTeamMembers] = useState<UserInfo[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    async function handleValidateTask(taskId: string) {
        try {
            await axios.post(
                `/api/constructionTask/status/${taskId}`,
                {},
                { headers: getAuthorizedHeader() }
            );
            onClose();
            fetchTask();
        } catch (error) {
            console.error("Error toggling task status:", error);
        }
    }

    useEffect(() => {
        if (!task.assignedId) return;

        const fetchAssignedEntity = async () => {
            setIsLoading(true);
            try {
                if (task.type === "person") {
                    const userRes = await axios.get<UserInfo>(
                        `/api/user/${task.assignedId}`,
                        { headers: getAuthorizedHeader() }
                    );
                    setUser(userRes.data);
                    setTeam(null);
                    setTeamMembers([]);
                } else if (task.type === "team") {
                    const teamRes = await axios.get<TeamInfo>(
                        `/api/team/${task.assignedId}`,
                        { headers: getAuthorizedHeader() }
                    );
                    const fetchedTeam = teamRes.data;
                    setTeam(fetchedTeam);
                    setUser(null);

                    if (fetchedTeam.employees?.length > 0) {
                        const memberPromises = fetchedTeam.employees.map((empId) =>
                            axios.get<UserInfo>(`/api/user/${empId}`, {
                                headers: getAuthorizedHeader(),
                            })
                        );
                        const responses = await Promise.all(memberPromises);
                        const membersData = responses.map((r) => r.data);
                        setTeamMembers(membersData);
                    } else {
                        setTeamMembers([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching assigned entity:", error);
                setUser(null);
                setTeam(null);
                setTeamMembers([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAssignedEntity();
    }, [task.type, task.assignedId]);

    function renderAssignedEntity() {
        if (isLoading) {
            return (
                <Flex align="center">
                    <Spinner size="sm" mr={2} />
                    <Text fontSize="sm" fontWeight="medium" color="gray.700">
                        {t("Loading")}...
                    </Text>
                </Flex>
            );
        }

        if (task.type === "person" && user) {
            return (
                <Flex align="center">
                    <Avatar
                        size="sm"
                        name={`${user.firstname} ${user.lastname}`}
                        src={user.profilePicture}
                        mr={2}
                    />
                    <Text fontSize="sm" fontWeight="medium" color="gray.700">
                        {user.firstname} {user.lastname}
                    </Text>
                </Flex>
            );
        }

        if (task.type === "team" && team) {
            return (
                <Box textAlign="center">
                    <Text fontWeight="semibold" color="gray.800" mb={1}>
                        {team.name}
                    </Text>
                    {teamMembers.length > 0 ? (
                        <AvatarGroup size="sm" max={3}>
                            {teamMembers.map((member) => (
                                <Avatar
                                    key={member._id}
                                    name={`${member.firstname} ${member.lastname}`}
                                    src={member.profilePicture}
                                />
                            ))}
                        </AvatarGroup>
                    ) : (
                        <Text fontSize="sm" color="gray.500">
                            {t("No team members")}
                        </Text>
                    )}
                </Box>
            );
        }

        return null;
    }

    const taskStart = new Date(task.startDate);
    const taskEnd = new Date(task.endDate);
    const taskDuration = differenceInDays(taskEnd, taskStart) + 1;

    return (
        <Td key={index} textAlign="center" colSpan={colSpan}>
            <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
            >
                <PopoverTrigger>
                    <Box
                        bg={taskColor}
                        borderColor={`${getStatusColor(status)}.900`}
                        borderWidth="2px"
                        rounded="lg"
                        w="100%"
                        mt={2}
                        p="0.5rem"
                        fontWeight="bold"
                        cursor="pointer"
                        textAlign="center"
                        _hover={{
                            bg: isTaskValidated
                                ? "green.500"
                                : taskColor.replace(".300", ".400"),
                            transform: "scale(1.05)",
                        }}
                    >
                        {task.name}
                    </Box>
                </PopoverTrigger>

                <PopoverContent boxShadow="lg">
                    <PopoverArrow />
                    <PopoverBody p={6} bg="gray.50" borderRadius="md" textAlign="center">
                        <Box mb={4}>
                            <Text fontSize="lg" fontWeight="bold" color="blue.600" mb={2}>
                                {task.name}
                            </Text>
                        </Box>

                        <Box mb={4}>
                            <Flex justify="space-between">
                                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                    <strong>{t("Begin")}:</strong> {task.startDate}
                                </Text>
                                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                    <strong>{t("End")}:</strong> {task.endDate}
                                </Text>
                            </Flex>
                        </Box>

                        <Box mb={6}>
                            <Text
                                fontSize="sm"
                                fontWeight="medium"
                                color="gray.600"
                                mb={2}
                            >
                                <strong>{t("Assigné à")}:</strong>
                            </Text>
                            {renderAssignedEntity()}
                        </Box>

                        <Flex
                            justify="space-between"
                            align="center"
                            mt={4}
                            pt={4}
                            borderTop="1px solid"
                            borderColor="gray.200"
                        >
                            <Box>
                                <Text
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="gray.600"
                                >
                                    <strong>{t("Durée")}:</strong> {taskDuration} {t("days")}
                                </Text>
                            </Box>
                            <Button
                                size="sm"
                                colorScheme={isTaskValidated ? "green" : "yellow"}
                                onClick={() => handleValidateTask(task._id)}
                                boxShadow="sm"
                                _hover={{
                                    bg: isTaskValidated ? "green.500" : "blue.500",
                                }}
                            >
                                {isTaskValidated ? t("Terminé") : t("Valider")}
                            </Button>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Td>
    );
}

export default TaskDetail;
