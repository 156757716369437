import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    useDisclosure,
    Box, useBreakpointValue
} from "@chakra-ui/react";
import InputComponent from "../common/InputComponent";
import {IRegisterFormData} from "./RegisterComponent";
import ButtonComponent from "../common/ButtonComponent";

interface NewTeamProps {
    addTeam: (teamName: string) => void;
}
interface ITeamFormData {
    name: string;
}

function NewTeam(props: NewTeamProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    const [formData, setFormData] = useState({
        name: '',
    } as ITeamFormData);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.addTeam(formData.name);
        onClose();
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleOpenModal = () => {
        onOpen();
    };

    return (
        <Box width={"100%"}>
            <Box ml={isSmallScreen ? 1 : 5}
                 mr={isSmallScreen ? 3 : 5}>
                <ButtonComponent text={'New Teams'} callback={() => handleOpenModal()}/>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nouvelle Équipe</ModalHeader>
                    <ModalCloseButton
                        position="absolute"
                        top="1rem"
                        right="1rem"
                        bg="transparent"
                        _hover={{bg: 'transparent'}}
                        _active={{bg: 'transparent'}}
                        sx={{
                            '& svg': {
                                transition: 'all 0.2s ease-in-out',
                            },
                            '& svg path': {
                                stroke: 'white',
                                transition: 'stroke 0.3s ease-in-out',
                            },
                            '&:hover svg path': {
                                stroke: 'red',
                                transition: 'stroke 0.5s ease-in-out 0s',
                            },
                        }}/>
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <label>
                                <InputComponent label={"Name Teams"} name={"name"} type={"text"} value={formData.name} onChange={handleChange} isRequired/>
                            </label>
                            <Button type="submit" mt="4">Ajouter</Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default NewTeam;
